<template>
    <div>
        <div
            style="
                font-size: 34px;
                font-weight: bold;
                margin-bottom: 22px;
                color: red;
            "
        >
            <p><span style="font-size: 14px">Sử dụng thẻ: </span>span</p>
            <p><span style="font-size: 14px">Sử dụng class: </span>icon-*</p>
        </div>
        <div>
            <p class="text-medium text-h1">List Icons</p>
            <div class="section-color">
                <el-row :gutter="10">
                    <el-col
                        :span="6"
                        class="component-preview"
                        v-for="(item, index) in ListIcon.icons"
                        :key="index"
                    >
                        <span :class="`icon-${item.properties.name}`"></span>
                        <span>{{ `icon-${item.properties.name}` }}</span>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import ListIcon from "../../assets/icomoon/selection.json";
export default {
    name: "Radio",
    components: {},
    data() {
        return {
            colorsPrimary: [],
            ListIcon: ListIcon,
        };
    },
    methods: {
        getClass(item) {
            let className = `bg-${item.Name}-${item.Sub}`;
            return className.toLowerCase();
        },
        changeValue(value) {
            console.log("value :>> ", value);
            console.log("this.value3 :>> ", this.value3);
        },
    },
};
</script>

<style lang="scss" scoped>
.is-title {
    font-weight: 600;
    font-size: 25px;
    color: #000000;
}

.section-color {
    padding-top: 24px;
    display: flex;
    gap: 40px;
    .block-info {
        padding-top: 10px;
    }
}

.component-preview span {
    font-size: 20px;
    line-height: 44px;
    margin: 0 8px;
}
</style>
